<script lang="ts" setup>
import { promiseTimeout, useIntervalFn } from "@vueuse/core";

const props = defineProps<{
  fallback?: string;
  header: {
    part_1?: string | null | undefined;
    part_3?: string | null | undefined;
    animated_item?:
      | ({
          part_2?: string | null | undefined;
          part_4?: string | null | undefined;
        } | null)[]
      | null;
  };
}>();

const part_2 = ref<string | null | undefined>(
  props.header?.animated_item ? props.header?.animated_item[0]?.part_2 : ""
);
const part_4 = ref<string | null | undefined>(
  props.header?.animated_item ? props.header?.animated_item[0]?.part_4 : ""
);
const index = ref<number>(1);
const selected = ref<boolean>(false);
const show = ref<boolean>(true);

useHeaderColor("white");

const { isActive, pause, resume } = useIntervalFn(async () => {
  if (props.header?.animated_item) {
    selected.value = true;
    await promiseTimeout(500);
    show.value = false;
    part_2.value = "";
    selected.value = false;
    await promiseTimeout(200);
    show.value = true;

    typing();
    part_4.value = props.header?.animated_item[index.value]?.part_4;
    index.value =
      index.value + 1 >= props.header?.animated_item.length
        ? 0
        : index.value + 1;
  }
}, 5000);

onMounted(() => {
  document.addEventListener("visibilitychange", (event) => {
    if (document.visibilityState == "visible") {
      resume();
    } else {
      pause();
    }
  });
});

async function typing() {
  if (!props.header?.animated_item) return;
  for (let char of props.header?.animated_item[index.value]?.part_2) {
    await promiseTimeout(60);
    part_2.value += char;
  }
}
</script>
<template>
  <header
    class="header-stage relative bg-integer-white h-4/5vh w-full"
  >
    <div class="grid h-full w-full content-center py-10 container outer-space">
      <h1
        v-if="
          props.header?.part_1 &&
          props.header?.part_3 &&
          props.header?.animated_item?.length
        "
        class="text-h2 md:max-2xl:text-h1 2xl:text-h1max font-pixel absolute top-1/2 -translate-y-1/2"
      >
        <span class="inline-block overflow-hidden">
          {{ props.header?.part_1 }}&nbsp;</span
        >

        <span
          class="inline-block overflow-hidden relative z-0 select"
          :class="{ active: selected }"
          v-show="show"
        >
          <span v-for="(char, index) in part_2" :key="char + index">{{
            char
          }}</span
          >&nbsp;</span
        >
        <span class="inline-block overflow-hidden"
          >{{ props.header?.part_3 }}&nbsp;</span
        >
        <br />
        <transition name="slide-up" mode="out-in"
          ><span class="block text-integer-light-green" :key="part_4"
            >{{ part_4 }}<span class="text-integer-black">.</span></span
          ></transition
        >
      </h1>
      <h1 v-else class="text-h2 md:max-2xl:text-h1 2xl:text-h1max font-pixel max-w-4/5">
        {{ props.fallback }}
      </h1>
    </div>
  </header>
</template>
<style scoped>
.transition {
  transition: all 0.3s ease-out;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.3s ease-in-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

.select:before {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  transform: translateX(101%);
  z-index: -1;
  @apply bg-integer-light-green;

  transition: 500ms;
}

.select:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  transform: translateX(101%);
  z-index: -1;
  @apply filter;

  transition: 500ms;
}

.select.active:before {
  transform: translateX(-1rem);
}
</style>
